.app-buttons,
.app-buttons-dark {
  background-color: transparent;
}

.app-buttons a,
.app-buttons-dark a {
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin: auto 20px;
}

.app-buttons-dark a {
  color: white;
}

.app-button,
.app-button-dark {
  border-radius: 5px;
  padding: 15px 0;
  width: 220px;
  white-space: nowrap;
}

.app-button {
  background-color: #101010;
  border: 1px solid gray;
  color: white;
}

.app-button-dark {
  background-color: white;
  color: black;
}

@media (hover: hover) {
  .app-buttons a:hover,
  .app-buttons-dark a:hover {
    color: gray;
    text-decoration: none;
  }

  .app-button:hover,
  .app-button-dark:hover {
    background-color: gray;
  }
}
