$backgroundImageDir: '';
// $backgroundImageDir: 'christmas/';
// $backgroundImageDir: 'new_years/';

$backgroundImagePath: #{"../../assets/background/" + $backgroundImageDir};

#Home {
  background-image: url(#{$backgroundImagePath + "original.jpg"});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
}

#Home .img-circle {
  width: 100px;
  height: 100px;
}

#Home h2 {
  color: lightgray;
}

#Home h1,
#Home h2 {
  margin: 0;
}

#Home h1 {
  font-size: 4em;
}

#Home h2 {
  font-size: 2em;
}

#PhotoRef {
  color: gray;
  font-size: 0.85em;
  font-style: italic;
  padding: 10px;
  text-align: left;
}

#PhotoRef a {
  color: white;
}

@media (max-width: 2400px) {
  #Home {
    background-image: url(#{$backgroundImagePath + "large.jpg"});
  }
}

@media (max-width: 1920px) {
  #Home {
    background-image: url(#{$backgroundImagePath + "medium.jpg"});
  }
}

@media (max-width: 640px) {
  #Home {
    background-image: url(#{$backgroundImagePath + "small.jpg"});
  }
}

@media (max-width: 660px) {
  #Home h1 {
    font-size: 3em;
  }
  
  #Home h2 {
    font-size: 1.5em;
  }
}

@media (max-width: 360px) {
  #Home h1 {
    font-size: 2em;
  }
  
  #Home h2 {
    font-size: 1em;
  }
}
