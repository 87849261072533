@import 'src/index.scss';

#App {
  overflow: hidden;
}

#AppMain {
  overflow-x: hidden;
  overflow-y: auto;
}

.border {
  border: 1px solid red;
}

.center {
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.left {
  justify-content: left;
  margin-left: 0;
  margin-right: auto;
  text-align: left;
}

.right {
  justify-content: right;
  margin-right: 0;
  margin-left: auto;
  text-align: right;
}

.color-gray {
  color: #222;
}

body.dark .color-gray {
  color: #CCC;
}

.divider {
  background-color: gray;
  content: '';
  display: inline-block;
  width: 2px;
  height: 30px;
}

.flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-reverse {
  flex-wrap: wrap-reverse;
}

.flex-vertical {
  flex-direction: column;
  flex-wrap: nowrap;
}

.full-size {
  width: 100%;
  height: 100%;
}

.img-circle {
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.margin-top-5 {
  margin-top: 5px;
}

.max-500 {
  max-width: 500px;
}

.max-600 {
  max-width: 600px;
}

.max-900 {
  max-width: 900px;
}

.middle {
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
}

.overlay {
  background: rgba(0, 0, 0, 0.8);
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.padding-left-right-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.padding-bottom-20 {
  padding-bottom: 20px;
}

.padding-top-10 {
  padding-top: 10px;
}

.padding-top-20 {
  padding-top: 20px;
}

.page {
  padding: 20px;
}

.responsive {
  width: 100%;
  height: auto;
}

.text-nowrap {
  white-space: nowrap;
}

@media (hover: hover) {
  .clickable:hover {
    cursor: pointer;
  }
}
