@import 'highlight.js/scss/github-dark.scss';

#Markdown,
#Markdown h1 {
  text-align: left;
}

#Markdown img[alt=social-media-icon] {
  margin-top: auto;
  margin-bottom: auto;
  vertical-align: middle;
  width: 32px;
  height: 32px;
}

body.dark #Markdown img[alt=social-media-icon] {
  filter: invert(100%);
}

@media (hover: hover) {
  #Markdown img[alt=social-media-icon]:hover {
    opacity: 0.7;
  }
}
