#Articles header select {
  background-color: #101010;
  border-radius: 3px;
  color: white;
  font-size: 1.1em;
  padding: 10px;
  width: 100%;
}

@media (max-width: 660px) {
  #Articles header select {
    font-size: 1em;
  }
}

@media (max-width: 360px) {
  #Articles header select {
    font-size: 0.9em;
  }
}
