#About {
  text-align: center;
}

#About h1,
#About h2 {
  margin: 0;
}

#About .img-circle {
  width: 85px;
  height: 85px;
}

@media (max-width: 660px) {
  #About h1 {
    font-size: 1.6em;
  }

  #About h2 {
    font-size: 1.2em;
  }
}

@media (max-width: 360px) {
  #About h1 {
    font-size: 1.5em;
  }

  #About h2 {
    font-size: 1.1em;
  }
}
