#AppFeatures {
  padding: 20px 0;
}

@media (max-width: 1000px) {
  #AppFeatures .left {
    flex-direction: column-reverse;
    text-align: center;
  }

  #AppFeatures .right {
    flex-direction: column;
    text-align: center;
  }
}
