@import 'src/index.scss';

#Nav {
  background-color: #101010;
  color: $textColor-dark;
}

#Nav .flag {
  padding-right: 20px;
}

#Nav .flag img {
  border-radius: 50%;
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

#Nav .logo img {
  width: 42px;
  height: 42px;
}

#Nav .dark-icon {
  padding: 20px 30px;
}

body.dark #Nav .dark-icon {
  transform: scaleX(-1);
}

#Nav .subnav svg {
  margin: auto 5px;
}

#Nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#Nav li {
  padding: 20px;
}

#Nav a {
  color: $textColor-dark;
}

#Nav .selected {
  border-bottom: 3px solid $titleColor;
}

#Nav .subnav-content .selected {
  border-bottom: none;
  color: $titleColor;
}

.subnav-content {
  background-color: #303030;
  display: none;
  text-align: left;
  position: absolute;
}

.subnav-content a {
  padding: 10px 30px;
}

@media (hover: hover) {
  #Nav a:hover {
    text-decoration: none;
  }

  #Nav .links a:hover {
    border-bottom: 3px solid $textColor-dark;
  }

  #Nav .dark-icon:hover {
    color: gray;
  }

  .subnav:hover .subnav-content {
    display: block;
  }

  #Nav .subnav a:hover {
    border-bottom: none;
    background-color: #404040;
  }
}

@media (max-width: 660px) {
  #Nav .logo {
    display: none;
  }
}

@media (max-width: 360px) {
  #Nav li {
    padding: 10px;
  }
}

@media (max-width: 300px) {
  #Nav li {
    padding: 8px;
  }
}
