#Timeline h2 {
  margin: 5px 0;
  text-align: left;
}

.timeline {
  position: relative;
  text-align: left;
}

.timeline h3,
.timeline h4 {
  margin-bottom: 0;
  margin-top: 5px;
}

.timeline::after {
  background-color: #404040;
  content: '';
  width: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 26px;
}

.timeline-container {
  color: lightgray;
  padding: 10px 0 10px 55px;
  position: relative;
}

.timeline-container::before {
  border-style: solid;
  border-width: 10px;
  border-color: transparent #151515 transparent transparent;
  content: " ";
  position: absolute;
  top: 22px;
  left: 36px;
  z-index: 1;
}

.timeline-container::after {
  background-color: black;
  border: 2px solid #AAA;
  border-radius: 50%;
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 20px;
  left: 14px;
  z-index: 1;
}

.timeline-container a,
.timeline-container h3,
.timeline-container span {
  color: white;
}

.timeline-content {
  background-color: #151515;
  border-radius: 6px;
  padding: 5px 20px;
  position: relative;
}

@media (max-width: 960px) {
  .timeline-container {
    padding: 10px 10px 10px 55px;
  }
}

@media (max-width: 660px) {
  .timeline-container h3 {
    font-size: 1em;
  }
  
  .timeline-container h4,
  .timeline-container p {
    font-size: 0.9em;
  }
}

@media (max-width: 360px) {
  .timeline-container h3 {
    font-size: 0.9em;
  }
  
  .timeline-container h4,
  .timeline-container p {
    font-size: 0.8em;
  }
}
