@import 'src/index.scss';

.scroll-to-top {
  display: none;
  position: relative;
  text-align: center;
}

.scroll-to-top::after {
  background: gray;
  content: " ";
  display: block;
  width: 100%;
  height: 1px;
  position: absolute;
  left: 0;
  top: 50%;
}

.scroll-to-top-button {
  background-color: #101010;
  border: 1px solid gray;
  border-radius: 5px;
  color: $textColor-dark;
  display: inline-block;
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 15px 0;
  width: 220px;
  position: relative;
  z-index: 10;
}

.scroll-to-top-button svg {
  font-size: 1.0em;
  padding-right: 10px;
}

@media (hover: hover) {
  .scroll-to-top-button:hover {
    background-color: #303030;
  }
}
