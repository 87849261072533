#ModalImage {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

#ModalImage img {
  max-width: min(calc(100vw - 40px), 2504px);
  max-height: min(calc(100vh - 40px), 1752px);
}

#ModalImageClose {
  color: white;
  font-size: 4.5em;
  height: 80px;
  width: 80px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
}

@media (hover: hover) {
  #ModalImageClose:hover {
    color: gray;
  }
}
