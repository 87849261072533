#Apps {
  text-align: center;
}

#Apps summary {
  font-size: 1.5em;
}

#Apps .answer {
  font-size: 1.17em;
}

#AppHeader {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#AppHeader h2 {
  color: white;
}

#AppHeader h3 {
  color: lightgray;
}

#AppHeader .overlay {
  padding: 100px 20px;
}

@media (max-width: 660px) {
  #Apps h1 {
    font-size: 1.6em;
  }

  #Apps h2,
  #Apps summary {
    font-size: 1.2em;
  }

  #Apps h3,
  #Apps .answer {
    font-size: 1em;
  }
}

@media (max-width: 360px) {
  #Apps h1 {
    font-size: 1.5em;
  }

  #Apps h2,
  #Apps summary {
    font-size: 1.1em;
  }

  #Apps h3,
  #Apps .answer {
    font-size: 0.9em;
  }
}
