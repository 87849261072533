$titleColor: darkorange;
$titleColor-light: orangered;

$backgroundColor-dark: #202020;
$textColor-dark: white;

$backgroundColor-light: #fffff0;
$textColor-light: black;

a {
  text-decoration: none;
}

@media (hover: hover) {
  a:hover {
    text-decoration: underline;
  }
}

body a {
  color: $textColor-light;
}

body.dark a {
  color: $textColor-dark;
}

body {
  background-color: $backgroundColor-light;
  color: $textColor-light;
}

body.dark {
  background-color: $backgroundColor-dark;
  color: $textColor-dark;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  width: 100%;
  height: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

figure {
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding: 0;
}

h1,
h2,
h3,
h4 {
  font-weight: normal;
}

h1 {
  color: $titleColor-light;
  text-align: center;
}

body.dark h1,
.overlay h1 {
  color: $titleColor;
}

#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

svg {
  font-size: 1.5em;
}
