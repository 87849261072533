@import 'src/index.scss';

#Footer {
  padding: 20px;
}

#Footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

#Footer li {
  padding: 10px;
}

#Footer .selected {
  border-bottom: 3px solid $titleColor;
}

@media (hover: hover) {
  #Footer a:hover {
    text-decoration: none;
  }

  #Footer .icons a:hover {
    color: gray;
  }

  #Footer .links a:hover {
    border-bottom: 3px solid $textColor-light;
  }

  body.dark #Footer .links a:hover {
    border-bottom: 3px solid $textColor-dark;
  }
}

@media (max-width: 660px) {
  #Footer div,
  #Footer ul {
    justify-content: center;
    text-align: center;
  }
}

@media (max-width: 360px) {
  #Footer div {
    font-size: 0.85em;
  }
}
